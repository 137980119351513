// For <List.MenuItem> and many others we've opted to keep a mapping of icon
// names as they're more convenient, at the cost of slight bundle size increase
// for everyone using it.
// (tiny though, we're also bundling the SVG assets into a single spritesheet)

import { Component, h } from "vue";

import StripeIcon from "./brands/stripe.svg";
import UnsplashIcon from "./brands/unsplash.svg";
import ArchiveBoxIcon from "./heroicons/archive-box-micro.svg";
import ArrowDownTrayIcon from "./heroicons/arrow-down-tray-micro.svg";
import ArrowLeftIcon from "./heroicons/arrow-left-micro.svg";
import ArrowPathIcon from "./heroicons/arrow-path-micro.svg";
import ArrowPathRoundedSquareIcon from "./heroicons/arrow-path-rounded-square-micro.svg";
import ArrowRightEndOnRectangleIcon from "./heroicons/arrow-right-end-on-rectangle-micro.svg";
import ArrowRightIcon from "./heroicons/arrow-right-micro.svg";
import ArrowRightStartOnRectangleIcon from "./heroicons/arrow-right-start-on-rectangle-micro.svg";
import ArrowTopRightOnSquareIcon from "./heroicons/arrow-top-right-on-square-micro.svg";
import ArrowUTurnLeftIcon from "./heroicons/arrow-uturn-left-micro.svg";
import ArrowUTurnRightIcon from "./heroicons/arrow-uturn-right-micro.svg";
import BellIcon from "./heroicons/bell-micro.svg";
import BoltIcon from "./heroicons/bolt-micro.svg";
import CalculatorIcon from "./heroicons/calculator-micro.svg";
import CalendarIcon from "./heroicons/calendar-micro.svg";
import ChartBarIcon from "./heroicons/chart-bar-micro.svg";
import CheckIcon from "./heroicons/check-micro.svg";
import ClipboardIcon from "./heroicons/clipboard-micro.svg";
import CodeBracketIcon from "./heroicons/code-bracket-micro.svg";
import ComputerDesktopIcon from "./heroicons/computer-desktop-micro.svg";
import CreditCardIcon from "./heroicons/credit-card-micro.svg";
import CurrencyDollarIcon from "./heroicons/currency-dollar.svg";
import DivideIcon from "./heroicons/divide-micro.svg";
import DocumentArrowDownIcon from "./heroicons/document-arrow-down-micro.svg";
import DocumentArrowUpIcon from "./heroicons/document-arrow-up-micro.svg";
import DocumentTextIcon from "./heroicons/document-text-micro.svg";
import EnvelopeIcon from "./heroicons/envelope-micro.svg";
import EyeIcon from "./heroicons/eye-micro.svg";
import EyeSlashIcon from "./heroicons/eye-slash-micro.svg";
import FaceSmileIcon from "./heroicons/face-smile-micro.svg";
import FileCodeIcon from "./heroicons/file-code.svg";
import GiftIcon from "./heroicons/gift-micro.svg";
import GlobeAltIcon from "./heroicons/globe-alt-micro.svg";
import H1Icon from "./heroicons/h1-micro.svg";
import H2Icon from "./heroicons/h2-micro.svg";
import H3Icon from "./heroicons/h3-micro.svg";
import HandThumbUpIcon from "./heroicons/hand-thumb-up-micro.svg";
import InformationCircleIcon from "./heroicons/information-circle-micro.svg";
import LinkIcon from "./heroicons/link-micro.svg";
import LinkSlashIcon from "./heroicons/link-slash-micro.svg";
import ListBulletIcon from "./heroicons/list-bullet-micro.svg";
import LockClosedIcon from "./heroicons/lock-closed-micro.svg";
import MinusCircleIcon from "./heroicons/minus-circle-micro.svg";
import NoSymbolIcon from "./heroicons/no-symbol-micro.svg";
import NumberedListIcon from "./heroicons/numbered-list-micro.svg";
import PaperAirplaneIcon from "./heroicons/paper-airplane-micro.svg";
import PaperClipIcon from "./heroicons/paper-clip-micro.svg";
import PauseIcon from "./heroicons/pause-micro.svg";
import PencilIcon from "./heroicons/pencil-micro.svg";
import PencilSquareIcon from "./heroicons/pencil-square-micro.svg";
import PhotoIcon from "./heroicons/photo-micro.svg";
import PlayIcon from "./heroicons/play-micro.svg";
import PlusIcon from "./heroicons/plus-micro.svg";
import QuestionMarkCircleIcon from "./heroicons/question-mark-circle-micro.svg";
import RSSIcon from "./heroicons/rss-micro.svg";
import ShareIcon from "./heroicons/share-micro.svg";
import SparklesIcon from "./heroicons/sparkles-micro.svg";
import StopIcon from "./heroicons/stop-micro.svg";
import TableCellsIcon from "./heroicons/table-cells-micro.svg";
import TagIcon from "./heroicons/tag-micro.svg";
import TrashIcon from "./heroicons/trash-micro.svg";
import UserGroupIcon from "./heroicons/user-group-micro.svg";
import UserMinusIcon from "./heroicons/user-minus-micro.svg";
import UserPlusIcon from "./heroicons/user-plus-micro.svg";
import MdFormatQuoteIcon from "./material-symbols/format-quote.svg";
import MdSaveIcon from "./material-symbols/save.svg";
import MdSubscriptIcon from "./material-symbols/subscript.svg";
import MdSuperscriptIcon from "./material-symbols/superscript.svg";

export const iconMappingMicro = {
  bell: BellIcon,
  bolt: BoltIcon,
  calendar: CalendarIcon,
  check: CheckIcon,
  clipboard: ClipboardIcon,
  envelope: EnvelopeIcon,
  eye: EyeIcon,
  gift: GiftIcon,
  link: LinkIcon,
  pause: PauseIcon,
  play: PlayIcon,
  plus: PlusIcon,
  rss: RSSIcon,
  share: ShareIcon,
  sparkles: SparklesIcon,
  tag: TagIcon,
  trash: TrashIcon,

  "archive-box": ArchiveBoxIcon,
  "arrow-left": ArrowLeftIcon,
  "arrow-path-rounded-square": ArrowPathRoundedSquareIcon,
  "arrow-path": ArrowPathIcon,
  "arrow-right-end-on-rectangle": ArrowRightEndOnRectangleIcon,
  "arrow-right-start-on-rectangle": ArrowRightStartOnRectangleIcon,
  "arrow-right": ArrowRightIcon,
  "arrow-top-right-on-square": ArrowTopRightOnSquareIcon,
  "arrow-uturn-left": ArrowUTurnLeftIcon,
  "arrow-uturn-right": ArrowUTurnRightIcon,
  "chart-bar": ChartBarIcon,
  "code-bracket": CodeBracketIcon,
  "computer-desktop": ComputerDesktopIcon,
  "credit-card": CreditCardIcon,
  "document-arrow-down": DocumentArrowDownIcon,
  "document-arrow-up": DocumentArrowUpIcon,
  "document-text": DocumentTextIcon,
  "arrow-down-tray": ArrowDownTrayIcon,
  "eye-slash": EyeSlashIcon,
  "globe-alt": GlobeAltIcon,
  "hand-thumb-up": HandThumbUpIcon,
  "information-circle": InformationCircleIcon,
  "link-slash": LinkSlashIcon,
  "lock-closed": LockClosedIcon,
  "minus-circle": MinusCircleIcon,
  "no-symbol": NoSymbolIcon,
  "paper-airplane": PaperAirplaneIcon,
  "pencil-square": PencilSquareIcon,
  "question-mark-circle": QuestionMarkCircleIcon,
  "user-group": UserGroupIcon,
  "user-minus": UserMinusIcon,
  "user-plus": UserPlusIcon,
  "currency-dollar": CurrencyDollarIcon,
  "file-code": FileCodeIcon,

  "brand-stripe": StripeIcon,
  // Unsplash icon is too large
  "brand-unsplash": () => h(UnsplashIcon, { class: "p-0.5" }),

  // Editor formatting
  calculator: CalculatorIcon,
  divide: DivideIcon,
  h1: H1Icon,
  h2: H2Icon,
  h3: H3Icon,
  pencil: PencilIcon,
  photo: PhotoIcon,
  stop: StopIcon,
  "face-smile": FaceSmileIcon,
  "list-bullet": ListBulletIcon,
  "numbered-list": NumberedListIcon,
  "paper-clip": PaperClipIcon,
  "table-cells": TableCellsIcon,

  "md-format-quote": MdFormatQuoteIcon,
  "md-save": MdSaveIcon,
  "md-subscript": MdSubscriptIcon,
  "md-superscript": MdSuperscriptIcon,
} satisfies Record<string, Component>;

export type MappedIconMicro = keyof typeof iconMappingMicro;
