<template>
  <!-- When using `inline-block`, this span will default to fit the width of the content, not the container. Therefore, we need to explicitly set it to have 100% width -->
  <span
    class="inline-block w-full"
    @click.self="enter"
    @mouseenter="enter"
    @mouseleave="leave"
  >
    <span ref="reference">
      <slot name="icon" />
    </span>
    <Teleport v-if="showing && $slots.content" to="body">
      <div
        ref="popper"
        :class="{
          hidden: !showing,
          block: showing,
          'bg-gray-5 text-white text-tooltip text-center rounded-md p-2.5 w-32 h-min shadow-lg': true,
          [props.tooltipClasses ?? '']: true,
        }"
        style="z-index: 9999"
        @mouseenter="enter"
        @mouseleave="leave"
      >
        <div
          class="bg-gray-5 h-2 w-2 absolute rotate-45"
          data-target="tooltip-arrow"
        ></div>
        <slot name="content" />
      </div>
    </Teleport>
  </span>
</template>

<script lang="ts" setup>
import { Placement } from "@floating-ui/utils";
import { onUnmounted, ref, watch } from "vue";

import { useFloating } from "@/lib/popper";

export interface Props {
  placement?: Placement;
  tooltipClasses?: string;

  // "Interactive" tooltips have a debounce before hiding so that the mouse
  // can move from the hover target into the tooltip without it closing.
  interactive?: boolean;

  alwaysShow?: boolean;
  neverShow?: boolean;
}

const props = defineProps<Props>();

const showing = ref(false || props.alwaysShow);
watch(
  () => props.alwaysShow,
  () => {
    showing.value = props.alwaysShow;
  }
);
const [reference, popper] = useFloating({
  get placement() {
    return props.placement ?? "top";
  },
  flip: true,
  boundaryPadding: 16,
  distance: 10,
  arrow: {
    selector: "[data-target=tooltip-arrow]",
    padding: 6,
  },
});

const leaveTimeout = ref<ReturnType<typeof setTimeout>>();

const enter = () => {
  if (props.alwaysShow || props.neverShow) {
    return;
  }

  if (leaveTimeout.value) {
    clearTimeout(leaveTimeout.value);
  }

  showing.value = true;
};

const leave = () => {
  if (props.alwaysShow || props.neverShow) {
    return;
  }

  leaveTimeout.value = setTimeout(
    () => {
      showing.value = false;
    },
    props.interactive ? 200 : 0
  );
};

onUnmounted(() => {
  if (leaveTimeout.value) {
    clearTimeout(leaveTimeout.value);
  }
});

watch(showing, (isShowing) => {
  if (!isShowing) {
    popper.value = null;
  }
});
</script>
