<template>
  <a v-if="props.href" :href="props.href" class="no-underline" target="_blank">
    <div
      :class="{
        'm-0 relative space-x-2 rounded-md text-sm font-medium focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-300 h-auto items-center flex px-2 py-1 no-underline transition-colors': true,
        'bg-blue-500 hover:bg-blue-600 text-white': variant === 'primary',
        'bg-gray-100 border border-gray-200 hover:bg-gray-200 hover:border-gray-300 text-gray-700':
          variant === 'secondary',
        'bg-red-500 hover:bg-red-600 text-white': variant === 'negative',
        'cursor-not-allowed opacity-50': disabled,
      }"
    >
      <ArrowPathIcon v-if="pending" class="size-4 animate-spin" />
      <component :is="iconMappingMicro[icon]" v-else-if="icon" class="size-4" />
      <div>{{ props.text }} ↗</div>
      <div
        v-if="hotkey"
        :class="{
          'hidden lg:block text-xs font-mono uppercase rounded px-1': true,
          'bg-black/50': variant === 'primary' || variant === 'negative',
          'bg-black/25': variant === 'secondary',
        }"
      >
        {{ formatKeybind(hotkey) }}
      </div>
    </div>
  </a>
  <RouterLink v-else-if="props.route" :to="props.route" class="no-underline">
    <div
      :class="{
        'm-0 relative space-x-2 rounded-md text-sm font-medium focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-300 h-auto items-center flex px-2 py-1 no-underline transition-colors': true,
        // Otherwise, it wraps on mobile and looks janky.
        'whitespace-nowrap': true,
        'bg-blue-500 hover:bg-blue-600 text-white': variant === 'primary',
        'bg-gray-100 border border-gray-200 hover:bg-gray-200 hover:border-gray-300 text-gray-700':
          variant === 'secondary',
        'bg-red-500 hover:bg-red-600 text-white': variant === 'negative',
        'cursor-not-allowed opacity-50': disabled,
      }"
    >
      <ArrowPathIcon v-if="pending" class="size-4 animate-spin" />
      <component :is="iconMappingMicro[icon]" v-else-if="icon" class="size-4" />
      <div>{{ props.text }}</div>
      <div
        v-if="hotkey"
        :class="{
          'hidden lg:block text-xs font-mono uppercase rounded px-1': true,
          'bg-black/50': variant === 'primary' || variant === 'negative',
          'bg-black/25': variant === 'secondary',
        }"
      >
        {{ formatKeybind(hotkey) }}
      </div>
    </div>
  </RouterLink>
  <div v-else class="cursor-default" @click="emit('click')">
    <div
      :class="{
        'm-0 relative space-x-2 rounded-md text-sm font-medium focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-300 h-auto items-center flex px-2 py-1 no-underline whitespace-nowrap transition-colors': true,
        'bg-blue-500 hover:bg-blue-600 text-white': variant === 'primary',
        'bg-gray-100 border border-gray-200 hover:bg-gray-200 hover:border-gray-300 text-gray-700':
          variant === 'secondary',
        'bg-red-500 hover:bg-red-600 text-white': variant === 'negative',
        'cursor-not-allowed opacity-50': disabled,
        'bg-gray-200 border-gray-300 hover:bg-gray-300': active,
      }"
    >
      <ArrowPathIcon v-if="pending" class="size-4 animate-spin" />
      <component :is="iconMappingMicro[icon]" v-else-if="icon" class="size-4" />
      <div>{{ props.text }}</div>
      <div
        v-if="hotkey"
        :class="{
          'hidden lg:block text-xs font-mono uppercase rounded px-1': true,
          'bg-black/50': variant === 'primary' || variant === 'negative',
          'bg-black/25': variant === 'secondary',
        }"
      >
        {{ formatKeybind(hotkey) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router/auto";

import ArrowPathIcon from "@/icons/heroicons/arrow-path-micro.svg";
import {
  type MappedIconMicro,
  iconMappingMicro,
} from "@/icons/icon-mapping-micro";
import { formatKeybind, useKeybinds } from "@/lib/hotkeys";
import { RouteLocation } from "@/router/types";

export type Variant = "primary" | "secondary" | "negative";

const router = useRouter();
const props = defineProps<{
  route?: RouteLocation | null;
  href?: string;
  text: string;
  hotkey?: string;
  icon?: MappedIconMicro;
  variant: Variant;
  disabled?: boolean;
  pending?: boolean;
  active?: boolean;
}>();

const emit = defineEmits(["click"]);

useKeybinds(() => {
  const hotkey = props.hotkey;
  if (!hotkey) {
    return;
  }

  return {
    [hotkey]() {
      if (props.disabled) {
        return;
      }

      if (props.route) {
        router.push(props.route);
      } else {
        emit("click");
      }
    },
  };
});
</script>
