const getPartPosition = (
  type: keyof Intl.DateTimeFormatPartTypesRegistry
): number | undefined => {
  switch (type) {
    case "year":
      return 0;
    case "month":
      return 1;
    case "day":
      return 2;
    case "hour":
      return 3;
    case "minute":
      return 4;
    case "second":
      return 5;
  }
};

const dtfCache: Record<string, Intl.DateTimeFormat | undefined> = {};
export const asTimezonedDate = (date: Date, tz: string): Date => {
  // This is lifted from date-fns-tz, it seems that this function alone is
  // enough so long as we're converting local time directly to the specified
  // timezone. We're also skipping some of the workarounds here as the happy
  // path seems to be widely supported at this point.
  // https://github.com/marnusw/date-fns-tz/blob/00b48b2cd4505a69203aac5734773114bce13204/src/_lib/tzTokenizeDate/index.ts#L59

  let dtf = dtfCache[tz];
  if (dtf === undefined) {
    // https://caniuse.com/mdn-javascript_builtins_intl_locale_hourcycle
    // 2024-07-02: 95.95% supported globally
    dtf = dtfCache[tz] = new Intl.DateTimeFormat("en-US", {
      hourCycle: "h23",
      timeZone: tz,
      year: "numeric",
      month: "numeric",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  try {
    // https://caniuse.com/mdn-javascript_builtins_intl_datetimeformat_formattoparts
    // 2024-07-02: 95.9% supported globally
    const formatted = dtf.formatToParts(date);
    const tokens: number[] = [];

    for (let i = 0; i < formatted.length; i++) {
      const part = formatted[i];
      const pos = getPartPosition(part.type);

      if (pos !== undefined) {
        tokens[pos] = parseInt(part.value, 10);
      }
    }

    // Drop miliseconds because DateTimeFormat doesn't return it
    const tzDate = new Date(0);
    tzDate.setFullYear(tokens[0], tokens[1] - 1, tokens[2]);
    tzDate.setHours(tokens[3] % 24, tokens[4], tokens[5], 0);

    return tzDate;
  } catch (error) {
    if (error instanceof RangeError) {
      return new Date(NaN);
    }

    throw error;
  }
};

export const formatTimezoneToLocation = (tz: string) => {
  const splits = tz.split("/");
  const location = splits[splits.length - 1];

  return location.replaceAll("_", " ");
};
