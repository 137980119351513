<template>
  <Tooltip v-if="date" tooltip-classes="!w-[275px]">
    <template #icon>
      <span class="tabular-nums truncate">
        {{
          relative
            ? formatRelativeTime(date) + ` ago`
            : withTime
            ? formatDateTime(date)
            : formatDate(date)
        }}
      </span>
    </template>
    <template #content>
      <div class="text-[12px] mb-1 uppercase font-bold text-gray-300">
        Time conversion
      </div>
      <div class="grid grid-cols-[1fr,3fr] w-full whitespace-nowrap">
        <div class="text-left">
          {{ formatTimezoneToLocation(systemTimezone) }}
        </div>
        <div class="text-right font-bold tabular-nums">
          {{ formatDateTime(date) }}
        </div>

        <template v-if="newsletterTimezone && dateInNewsletterTz">
          <div class="text-left">
            {{ formatTimezoneToLocation(newsletterTimezone) }}
          </div>
          <div class="text-right font-bold tabular-nums">
            {{ formatDateTime(dateInNewsletterTz) }}
          </div>
        </template>

        <template v-if="dateInUTC">
          <div class="text-left">UTC</div>
          <div class="text-right font-bold tabular-nums">
            {{ formatDateTime(dateInUTC) }}
          </div>
        </template>
      </div>
    </template>
  </Tooltip>
  <span v-else class="hidden lg:block">—</span>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import Tooltip from "@/components/Utilities/Tooltip.vue";
import { formatDate, formatDateTime, formatRelativeTime } from "@/lib/time";
import { useStore as useScaffoldingStore } from "@/store/stores/scaffolding";

import {
  asTimezonedDate,
  formatTimezoneToLocation,
} from "./timezone-conversion";

const props = defineProps<{
  value: string | undefined;
  relative?: boolean;
  withTime?: boolean;
}>();

const store = useScaffoldingStore();

const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const newsletterTimezone = computed(() => store.newsletter?.timezone);

const date = computed(() => {
  if (!props.value) return undefined;
  return new Date(props.value);
});
const dateInUTC = computed(() => {
  if (!date.value) return undefined;
  return asTimezonedDate(date.value, "UTC");
});
const dateInNewsletterTz = computed(() => {
  if (!date.value || !newsletterTimezone.value) return undefined;

  // Don't show if system timezone is the same
  if (newsletterTimezone.value === systemTimezone) return undefined;
  // Don't show if timezone is UTC as we're already showing it
  if (newsletterTimezone.value.includes("UTC")) return;

  return asTimezonedDate(date.value, newsletterTimezone.value);
});
</script>
