<template>
  <tr
    :class="{
      'hover:bg-gray-100 border-1 border-gray-100 rounded group lg:table-row lg:mb-4 flex items-center w-full lg:[&>td:first-child]:px-4': true,
      // I know, I know — all of this looks insane, and there is probably a nicer way to materialize it. What we're doing here
      // is conceptually simple: 'The last column in every row should be right-aligned and unpadded, _unless_ that column is the first
      // real column.' This has to be accounted for three times to account for four different scenarios, as enumerated:
      // 1. There's an additional right-hand cell for actions _and_ an additional left-hand cell for the selection widget.
      '[&>:nth-last-child(2):not(:nth-child(2))]:!text-right [&>:nth-last-child(2):not(:nth-child(2))]:!pr-0':
        $slots.actions && selectable,
      // 2. There's and additional right-hand cell for actions.
      '[&>:nth-last-child(2):not(:nth-child(1))]:!text-right [&>:nth-last-child(2):not(:nth-child(1))]:!pr-0':
        $slots.actions && !selectable,
      // 3. There's an additional left-hand cell for selection.
      '[&>:nth-last-child(1):not(:nth-child(1))]:!text-right': !$slots.actions,
      // Also, add some padding to the first 'real' column if it's selectable.
      '[&>td:nth-child(2)]:px-4 lg:[&>td:nth-child(2)]:px-0': selectable,
      'bg-blue-100 hover:!bg-blue-200': selected,
      'bg-gray-200': active,
    }"
    @click="$emit('click')"
  >
    <td
      v-if="draggable || selectable"
      class="leading-6 items-center h-6 min-w-8 hidden lg:table-cell"
      scope="row"
    >
      <Checkbox
        v-if="selectable && selection"
        :model-value="selected"
        class="list-view-row-checkbox -mt-[2px]"
        @click="(e) => handleModelClicked && handleModelClicked(item, e)"
      />
    </td>
    <slot :item="item" />
    <td
      v-if="$slots.actions"
      class="pr-4 lg:pr-0 lg:w-4 flex-shrink-0 pl-2 block lg:table-cell align-middle"
    >
      <div class="flex items-center">
        <Menu.Root>
          <Menu.Trigger>
            <button class="lg:w-16 grid place-items-center">
              <EllipsisHorizontalIcon class="size-4" />
            </button>
          </Menu.Trigger>

          <Menu.Container>
            <slot name="actions" />
          </Menu.Container>
        </Menu.Root>
      </div>
    </td>
    <td
      v-if="slots['new-actions']"
      class="pr-4 lg:pr-0 lg:w-4 flex-shrink-0 pl-2 block lg:table-cell align-middle"
    >
      <Menu.Root>
        <Menu.Trigger>
          <button aria-label="Actions" class="grid place-items-center lg:w-16">
            <EllipsisHorizontalIcon class="size-4" />
          </button>
        </Menu.Trigger>

        <Menu.Container>
          <slot name="new-actions" />
        </Menu.Container>
      </Menu.Root>
    </td>
  </tr>
</template>

<script lang="ts" setup generic="Model">
import { computed } from "vue";

import { HandleModelClicked } from "@/components/DataTable/DataTable.vue";
import { Selection } from "@/components/Layout/ListView/lib";
import Checkbox from "@/components/Utilities/Forms/Checkbox.vue";
import Menu from "@/design_system/Menu";
import EllipsisHorizontalIcon from "@/icons/heroicons/ellipsis-horizontal-micro.svg";

const props = defineProps<{
  item?: any;
  draggable?: boolean;
  selectable?: boolean;
  selection?: Selection<Model>;
  handleModelClicked?: HandleModelClicked;
  active?: boolean;
}>();

const slots = defineSlots<{
  default(props: { item: any }): unknown;
  actions?(): unknown;
  "new-actions"?(): unknown;
}>();

defineEmits(["click"]);

const selected = computed(() => {
  const { item, selection } = props;

  // Sort of janky fix for #3210: we store full objects in `items`, but object-level equality
  // gets blown away if we re-fetch the list. This is a workaround to ensure that the selection is
  // maintained across re-fetches. (Ideally, we'd solve this by enforcing a generic constraint on `Model` —
  // `Model extends { id: string}`, maybe, or `Model extends { identifier: string }` — but that's a bigger
  // refactor than I want to do right now.)
  const identityFunction =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item && "id" in item
      ? (item: Model) => (item as any).id
      : (item: Model) => item;

  return (
    selection?.mode === "all" ||
    (selection?.mode === "all_except" && !selection.items.includes(item)) ||
    selection?.items.some(
      (selectedItem) =>
        identityFunction(selectedItem) === identityFunction(item)
    ) ||
    false
  );
});
</script>
