<template>
  <Dialog
    @close="
      () => {
        setDraft(null);
        if (parentRouteName) router.push(parentRouteName);
      }
    "
  >
    <template #header>
      <span v-if="data && data.length === 1 && data[0]">
        {{ verb }}
        <span class="underline">{{
          header ? header(data[0]) : data[0].id
        }}</span
        >?
      </span>
      <span v-else-if="data?.length">
        {{ verb }} {{ data.length }} {{ noun }}s?
      </span>
      <span
        v-else-if="
          count &&
          (props.selection.mode === 'all' ||
            (props.selection.items.length === 1 &&
              props.selection.items[0].id === 'all'))
        "
      >
        {{ verb }} {{ count }} {{ noun }}s?
      </span>
      <span
        v-else-if="
          count &&
          props.selection.mode !== 'all' &&
          props.selection.items.length === 1
        "
      >
        {{ verb }} one {{ noun }}?
      </span>
      <span v-else-if="count && props.selection.mode !== 'all'"
        >{{ verb }} {{ props.selection.items.length }} {{ noun }}s?</span
      >
      <span v-else>Loading...</span>
    </template>
    <template #body>
      <div v-if="exceededMaxIds">
        <p class="text-red-600 text-center text-sm font-medium">
          Unfortunately, you can’t execute changes on more than 200 records at a
          time.
        </p>
      </div>
      <div v-else>
        <!-- We include this check because otherwise, data is null which child components
        will interpret as "delete _everything_". This isn't a problem from a server-side standpoint,
     but is very scary if authors see a flash that says "You're about to delete all emails!" or whatever. -->
        <div v-if="!loading">
          <slot name="body" :data="data" :parameters="props.parameters" />
        </div>
      </div>
    </template>
    <template #footer>
      <div v-if="!exceededMaxIds" class="modal-footer">
        <ActionButton
          :text="
            verb.endsWith('to')
              ? verb.replace(' to', '')
              : verb.endsWith('from')
              ? verb.replace(' from', '')
              : verb
          "
          :icon="icon"
          hotkey="Enter"
          :disabled="mutationStatus === 'loading' || props.disabled"
          :pending="mutationStatus === 'loading'"
          @click="act"
        />
      </div>
      <div v-else>
        <!-- This empty element places _something_ in the modal footer,
          otherwise an empty modal footer renders a "Save" button. -->
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import type { components as OpenAPI } from "@/autogen/openapi";
import { Parameter } from "@/components/Layout/ParameterWidget/lib";
import ActionButton from "@/components/Utilities/ActionButton.vue";
import Dialog from "@/design_system/Dialog/Component.vue";
import { type MappedIconMicro } from "@/icons/icon-mapping-micro";
import useQuery from "@/lib/query";
import router from "@/router";
import { RouteLocation } from "@/router/types";
import { useStore as useBulkActionsStore } from "@/store/stores/bulk_actions";

import { Selection } from "../Layout/ListView/lib";

const props = defineProps<{
  selection: Selection<{ id: string }>;
  action: OpenAPI["schemas"]["BulkActionType"];
  verb: string;
  noun: string;
  icon: MappedIconMicro;
  parentRouteName?: RouteLocation;
  list?: (ids: string[]) => Promise<any[]>;
  count?: number;
  parameters?: Parameter<any>;
  payload: { [key: string]: any };
  disabled?: boolean;
  onSuccess?: () => void;
  header?: (item: any) => string;
}>();

const bulkActionsStore = useBulkActionsStore();

const setDraft = bulkActionsStore.setDraft;

const mutationStatus = computed(() =>
  bulkActionsStore.isCreatingBulkAction ? "loading" : "ready"
);

const MAXIMUM_LISTING_ID_COUNT = 10;

const { loading, data } = useQuery(() => {
  if (props.selection.mode === "all") {
    return Promise.resolve([]);
  }
  if (props.selection.items.length > MAXIMUM_LISTING_ID_COUNT) {
    return Promise.resolve([]);
  }
  return props.list
    ? props.list(props.selection.items.map((item) => item.id))
    : Promise.resolve([]);
});

const BULK_ACTION_MAX_IDS = 200;
const exceededMaxIds = computed(() => {
  if (props.selection.mode === "all") {
    return false;
  }
  return props.selection.items.length > BULK_ACTION_MAX_IDS;
});

const act = async () => {
  await bulkActionsStore.createBulkAction({
    data: {
      selection: props.selection,
      action_type: props.action,
      ...props.payload,
      parameters: props.parameters,
    },
    onSuccess: () => {
      if (props.onSuccess) {
        props.onSuccess();
      }
    },
  });
  if (props.parentRouteName) {
    router.push(props.parentRouteName);
  }
};
</script>
